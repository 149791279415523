<template>
  <!--begin::Layout-->
  <Overview />
  

</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, nextTick, ref } from "vue";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";

import { ElInput } from 'element-plus'
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import mAxiosApi from "@/api";
import Overview from "@/views/still/deploy/overview.vue"
import { useStore } from "vuex";
import { ElNotification } from 'element-plus';

import * as moment from "moment";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "customer-details",
  components: {
    Overview,
  },
 
  setup() {
    //moment.default.locale("fr");
    const { t } = useI18n()
    const state = reactive({
      loaderEnabled: true,
      lProducteurs: [] as any,
      lUsers: [] as any,
      drawer: false,
      drawer_bord : false,
      count: 0,
      paneBord : 1,
    });

    const form = reactive({
      type: '',
      producteur : '',
      dua : 30,
      tags : [] as any,
      refreglem : '',
      redacteur: 0,
      archiviste: 0,
      chefserv: 0,
      direction: 0,

    })

    const store = useStore();
    const router = useRouter();

    onMounted(async () => {
      MenuComponent.reinitialization();

      let mFilter = localStorage.filterBordereau;
      if (mFilter) {
        mFilter = mFilter.replace('/board/overview/', '');
        setCurrentPageBreadcrumbs("- Filtrés par statut "+ mFilter, []);
      } else {
        setCurrentPageBreadcrumbs("", []);
      }
      
      state.count = state.count + 1;
    });

    const newBordereau = async () => {
      form.type = "Conservation intégrale";     
      const myListLiv = await getAxios("/getSVProducteur");
      state.lProducteurs = myListLiv.results;
      form.producteur = "";
      form.dua = 30;
      form.refreglem = '';
      const myListUsers = await getAxios("/getSVUsers");
      state.lUsers = myListUsers.results;
     
      form.redacteur = store.getters.currentUser.uti_n_seq;
      state.drawer_bord = true;

    }

    const addNewBordereau = async () => {

      if (form.producteur == "") {  
        ElNotification({ title: t('Erreur'), message: t('Le champ Fournisseur/ Producteur est obligatoire'), type: 'error', })
        return false;
      }

      if (form.archiviste == 0) {  
        ElNotification({ title: t('Erreur'), message: t('Le champ Archiviste  est obligatoire'), type: 'error', })
        return false;
      }
    
      mAxiosApi
        .post("/addBordereau", form)
        .then((d: any) => {
          ElNotification({ title: t('Succès'), message: t('Bordereau créé avec succès !'), type: 'success', })
          state.count = state.count + 1;
          state.drawer_bord = false;

          // Affiche le bordereau
          affDetail(d.data);

        })
    }

    const inputValue = ref('')
    const inputVisible = ref(false)
    const InputRef = ref<InstanceType<typeof ElInput>>()

    const handleClose = (tag: string) => {
      form.tags.splice(form.tags.indexOf(tag), 1)
    }

    const showInput = () => {
      inputVisible.value = true
      nextTick(() => {
        if (InputRef.value) InputRef.value.input.focus()
      })
    }

    const handleInputConfirm = () => {
      if (inputValue.value) {
        form.tags.push(inputValue.value)
      }
      inputVisible.value = false
      inputValue.value = ''
    }


    const csvExport = async () => {

      const myListLiv = await getAxios("/getSVExport");

      const mArray = [] as any;
      
      myListLiv.results.forEach(element => {
        let myElem = JSON.stringify(element);
        
        myElem = myElem.replaceAll('\\n', " ");
        myElem = myElem.replaceAll('\\r', " ");
        myElem = myElem.replaceAll(";", '.');        
        mArray.push(JSON.parse(myElem));
      });

      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(mArray[0]).join(";"),
        ...mArray.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      
      const filename = "SV_ExportBordereaux_" + moment.default().format("DD.MM.YYYY_H:mm:ss") + ".csv";

      link.setAttribute("download", filename);
      link.click();
    };

    const affDetail = (d) =>{
        localStorage.setItem("currentBord", d.bor_n_seq);

        const currentBordType = d.bor_c_sort_versement == 'Conservation intégrale' || d.bor_c_sort_versement == 'Conservation partielle' ? 'versement' : 'élimination'
        localStorage.setItem("currentBordType", currentBordType);

        store.dispatch(Actions.SET_CURRENT_BORD, {
          bordSeq: d.bor_n_seq,
          bordType : currentBordType,
        });

        router.push({ name: "bord" });

    }

    

    return {
      state,
      form,
      handleClose,
      showInput,
      handleInputConfirm,
      inputValue,
      inputVisible,
      InputRef,
      newBordereau,
      addNewBordereau,
      csvExport,
      affDetail
    }
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}

</script>
<style>
.el-select-dropdown__item.new-bord {
    min-height: 48px;
    line-height: 24px;
}
</style>